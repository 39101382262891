import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import Helmet from "react-helmet";
import {
  ShoppingCartProvider,
  ShoppingCartStateConsumer,
} from "../contexts/shoppingCart";
import "../css/cc-styles.scss";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={(data) => (
        <React.Fragment>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: "Sample" },
              { name: "keywords", content: "sample, something" },
            ]}
          ></Helmet>
          <ShoppingCartProvider>
            <Header menuLinks={data.site.siteMetadata.menuLinks} />
            <section className="mt-6">
              <ShoppingCartStateConsumer>
                {({ checkout }) => {
                  const retVal =
                    checkout &&
                    checkout.lineItems &&
                    checkout.lineItems.length > 0 ? (
                      <div className="container">
                        <br />
                        <div className="notification is-warning is-light has-text-centered">
                          You have items in your cart.{" "}
                          <OutboundLink
                            href={checkout.webUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here
                          </OutboundLink>{" "}
                          to checkout
                        </div>
                      </div>
                    ) : null;
                  return retVal;
                }}
              </ShoppingCartStateConsumer>
            </section>
            <br />
            {children}
            <br />
            <Footer />
          </ShoppingCartProvider>
        </React.Fragment>
      )}
    />
  );
};

export default Layout;
