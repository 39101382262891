import React from "react";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Footer = () => (
  <footer className="footer">
    <div className="columns">
      <div className="column is-one-third"></div>
      <div className="column  has-text-centered">
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </div>
      <div className="column has-text-centered">
        <div className="content">
          <OutboundLink
            href="https://www.instagram.com/culturechauraha"
            style={{ color: "inherit" }}
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
          </OutboundLink>
          <OutboundLink
            href="https://www.facebook.com/culturechauraha/"
            style={{ color: "inherit" }}
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
          </OutboundLink>
          <OutboundLink
            href="https://www.twitter.com/culturechauraha/"
            style={{ color: "inherit" }}
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
          </OutboundLink>
        </div>
      </div>
      <div className="column  is-one-third"></div>
    </div>
    <div className="content has-text-centered">
      <p>
        Copyright &copy; {new Date().getFullYear()} Culture Chauraha. All rights
        reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
