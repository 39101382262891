import React, { createContext, useContext, useEffect, useReducer } from "react";
import { actions } from "./actions";
import shoppingCartReducer from "./reducer";

const ShoppingCartStateContext = createContext();
const ShoppingCartDispatchContext = createContext();

const ShoppingCartProvider = ({ children }) => {
  const initialState = {
    client: undefined,
    checkout: undefined,
  };

  const [state, dispatch] = useReducer(shoppingCartReducer, initialState);

  useEffect(() => {
    actions.initialize(dispatch);
  }, []);

  return (
    <ShoppingCartStateContext.Provider value={state}>
      <ShoppingCartDispatchContext.Provider value={dispatch}>
        {children}
      </ShoppingCartDispatchContext.Provider>
    </ShoppingCartStateContext.Provider>
  );
};

const useShoppingCartState = () => {
  const context = useContext(ShoppingCartStateContext);
  if (context === undefined) {
    throw new Error(
      `'useShoppingCartState' needs to be used within a ShoppingCartProvider`
    );
  }
  return context;
};

const useShoppingCartDispatch = () => {
  const context = useContext(ShoppingCartDispatchContext);
  if (context === undefined) {
    throw new Error(
      `'useShoppingCartDispatch' needs to be used within a ShoppingCartProvider`
    );
  }
  return context;
};

const useShoppingCart = () => {
  return [useShoppingCartState(), useShoppingCartDispatch()];
};

const ShoppingCartStateConsumer = ShoppingCartStateContext.Consumer;
const ShoppingCartDispatchConsumer = ShoppingCartDispatchContext.Consumer;

export {
  ShoppingCartProvider,
  useShoppingCart,
  useShoppingCartState,
  useShoppingCartDispatch,
  ShoppingCartStateConsumer,
  ShoppingCartDispatchConsumer,
};
