import React, { useState, useCallback } from "react";
import { Link } from "gatsby";
import logo from "../assets/cc-white-logo.png";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useShoppingCartState } from "../contexts/shoppingCart";

const Header = ({ menuLinks }) => {
  const { checkout } = useShoppingCartState();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const toggleMenuActive = useCallback(() => setIsMenuActive(!isMenuActive), [
    isMenuActive,
  ]);
  return (
    <header>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
        style={{ zIndex: 120 }}
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src={logo} alt="Culture Chauraha Logo" />
            </Link>
            <button
              className="button is-white navbar-burger mt-2 mr-3"
              aria-label="menu"
              aria-expanded="false"
              data-target="navMenu"
              id="navbarBurger"
              onClick={toggleMenuActive}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div
            className={isMenuActive ? "navbar-menu is-active" : "navbar-menu"}
            id="navMenu"
            style={{ zIndex: 120 }}
          >
            <div className="navbar-start"></div>
            <div className="navbar-end">
              {menuLinks.map((link) => (
                <Link
                  className="navbar-item"
                  to={link.link}
                  key={`key${link.link}`}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <Link className="navbar-item" to="/shopping-cart">
              {checkout &&
              checkout.lineItems &&
              checkout.lineItems.length > 0 ? (
                <span className="badge is-right">
                  {checkout.lineItems.length}
                </span>
              ) : null}
              <FontAwesomeIcon icon={faShoppingCart} size="1x" />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  menuLinks: PropTypes.array,
};

Header.defaultProps = {
  menuLinks: [],
};

export default Header;
