import { actionDescriptors } from "./actions";

const shoppingCartReducer = (state, action) => {
  switch (action.type) {
    case actionDescriptors.initialize:
      return {
        ...state,
        client: action.payload.client,
        checkout: action.payload.checkout,
      };
    case actionDescriptors.addLineItem:
    case actionDescriptors.removeLineItem:
      return {
        ...state,
        checkout: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: '${action.type}'`);
  }
};

export default shoppingCartReducer;
