import Client from "shopify-buy";

const LOCAL_STORAGE_KEY = "cc_shopify_checkout_id";

export const actionDescriptors = {
  initialize: "initialize",
  addLineItem: "addLineItem",
  removeLineItem: "removeLineItem",
};

export const actions = {
  initialize: async (dispatch) => {
    const client = Client.buildClient({
      storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      domain: process.env.GATSBY_SHOP_NAME,
    });

    const existingCheckoutId = localStorage.getItem(LOCAL_STORAGE_KEY);

    let existingCheckout = undefined;
    if (existingCheckoutId) {
      try {
        existingCheckout = await client.checkout.fetch(existingCheckoutId);
        if (existingCheckout.completedAt) {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          existingCheckout = undefined;
        }
      } catch (ex) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
    dispatch({
      type: actionDescriptors.initialize,
      payload: {
        client,
        checkout: existingCheckout,
      },
    });
  },
  addLineItem: async (dispatch, client, checkout, lineItem) => {
    let localCheckout = undefined;
    if (checkout === undefined) {
      localCheckout = await client.checkout.create();
      localStorage.setItem(LOCAL_STORAGE_KEY, localCheckout.id);
    } else {
      localCheckout = checkout;
    }

    localCheckout = await client.checkout.addLineItems(localCheckout.id, [
      lineItem,
    ]);
    dispatch({
      type: actionDescriptors.addLineItem,
      payload: localCheckout,
    });
  },
  removeLineItem: async (dispatch, client, checkoutId, lineItemId) => {
    const checkout = await client.checkout.removeLineItems(checkoutId, [
      lineItemId,
    ]);
    dispatch({
      type: actionDescriptors.removeLineItem,
      payload: checkout,
    });
  },
  addShippingAddress: undefined,
};
